import { observable } from '@trpc/server/observable';
import { t as transformResult, T as TRPCClientError } from '../transformResult-9a244fe7.mjs';
import { r as resolveHTTPLinkOptions, j as jsonHttpRequester } from '../httpUtils-8a5c637a.mjs';

function httpLinkFactory(factoryOpts) {
    return (opts)=>{
        const resolvedOpts = resolveHTTPLinkOptions(opts);
        return (runtime)=>({ op  })=>observable((observer)=>{
                    const { path , input , type  } = op;
                    const { promise , cancel  } = factoryOpts.requester({
                        ...resolvedOpts,
                        runtime,
                        type,
                        path,
                        input,
                        headers () {
                            if (!opts.headers) {
                                return {};
                            }
                            if (typeof opts.headers === 'function') {
                                return opts.headers({
                                    op
                                });
                            }
                            return opts.headers;
                        }
                    });
                    promise.then((res)=>{
                        const transformed = transformResult(res.json, runtime);
                        if (!transformed.ok) {
                            observer.error(TRPCClientError.from(transformed.error, {
                                meta: res.meta
                            }));
                            return;
                        }
                        observer.next({
                            context: res.meta,
                            result: transformed.result
                        });
                        observer.complete();
                    }).catch((cause)=>observer.error(TRPCClientError.from(cause)));
                    return ()=>{
                        cancel();
                    };
                });
    };
}
const httpLink = httpLinkFactory({
    requester: jsonHttpRequester
});

export { httpLink, httpLinkFactory };
