class TRPCClientError extends Error {
    static from(cause, opts = {}) {
        if (!(cause instanceof Error)) {
            return new TRPCClientError(cause.error.message ?? '', {
                ...opts,
                cause: undefined,
                result: cause
            });
        }
        if (cause.name === 'TRPCClientError') {
            return cause;
        }
        return new TRPCClientError(cause.message, {
            ...opts,
            cause,
            result: null
        });
    }
    constructor(message, opts){
        const cause = opts?.cause;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore https://github.com/tc39/proposal-error-cause
        super(message, {
            cause
        });
        this.meta = opts?.meta;
        this.cause = cause;
        this.shape = opts?.result?.error;
        this.data = opts?.result?.error.data;
        this.name = 'TRPCClientError';
        Object.setPrototypeOf(this, TRPCClientError.prototype);
    }
}

function isObject(value) {
    // check that value is object
    return !!value && !Array.isArray(value) && typeof value === 'object';
}

// FIXME:
// - the generics here are probably unnecessary
// - the RPC-spec could probably be simplified to combine HTTP + WS
/** @internal */ function transformResultInner(response, runtime) {
    if ('error' in response) {
        const error = runtime.transformer.deserialize(response.error);
        return {
            ok: false,
            error: {
                ...response,
                error
            }
        };
    }
    const result = {
        ...response.result,
        ...(!response.result.type || response.result.type === 'data') && {
            type: 'data',
            data: runtime.transformer.deserialize(response.result.data)
        }
    };
    return {
        ok: true,
        result
    };
}
/**
 * Transforms and validates that the result is a valid TRPCResponse
 * @internal
 */ function transformResult(response, runtime) {
    let result;
    try {
        // Use the data transformers on the JSON-response
        result = transformResultInner(response, runtime);
    } catch (err) {
        throw new TRPCClientError('Unable to transform response from server');
    }
    // check that output of the transformers is a valid TRPCResponse
    if (!result.ok && (!isObject(result.error.error) || typeof result.error.error.code !== 'number')) {
        throw new TRPCClientError('Badly formatted response from server');
    }
    if (result.ok && !isObject(result.result)) {
        throw new TRPCClientError('Badly formatted response from server');
    }
    return result;
}

export { TRPCClientError as T, transformResult as t };
