import { observable, tap } from '@trpc/server/observable';

/// <reference lib="dom.iterable" />
const palette = {
    query: [
        '72e3ff',
        '3fb0d8'
    ],
    mutation: [
        'c5a3fc',
        '904dfc'
    ],
    subscription: [
        'ff49e1',
        'd83fbe'
    ]
};
function isFormData(value) {
    if (typeof FormData === 'undefined') {
        // FormData is not supported
        return false;
    }
    return value instanceof FormData;
}
// maybe this should be moved to it's own package
const defaultLogger = (c = console)=>(props)=>{
        const { direction , type , path , context , id  } = props;
        const [light, dark] = palette[type];
        const rawInput = props.input;
        const input = isFormData(rawInput) ? Object.fromEntries(rawInput) : rawInput;
        const css = `
    background-color: #${direction === 'up' ? light : dark}; 
    color: ${direction === 'up' ? 'black' : 'white'};
    padding: 2px;
  `;
        const parts = [
            '%c',
            direction === 'up' ? '>>' : '<<',
            type,
            `#${id}`,
            `%c${path}%c`,
            '%O'
        ];
        const args = [
            css,
            `${css}; font-weight: bold;`,
            `${css}; font-weight: normal;`
        ];
        if (props.direction === 'up') {
            args.push({
                input,
                context: context
            });
        } else {
            args.push({
                input,
                result: props.result,
                elapsedMs: props.elapsedMs,
                context
            });
        }
        const fn = props.direction === 'down' && props.result && (props.result instanceof Error || 'error' in props.result.result) ? 'error' : 'log';
        c[fn].apply(null, [
            parts.join(' ')
        ].concat(args));
    };
function loggerLink(opts = {}) {
    const { enabled =()=>true  } = opts;
    const { logger =defaultLogger(opts.console)  } = opts;
    return ()=>{
        return ({ op , next  })=>{
            return observable((observer)=>{
                // ->
                enabled({
                    ...op,
                    direction: 'up'
                }) && logger({
                    ...op,
                    direction: 'up'
                });
                const requestStartTime = Date.now();
                function logResult(result) {
                    const elapsedMs = Date.now() - requestStartTime;
                    enabled({
                        ...op,
                        direction: 'down',
                        result
                    }) && logger({
                        ...op,
                        direction: 'down',
                        elapsedMs,
                        result
                    });
                }
                return next(op).pipe(tap({
                    next (result) {
                        logResult(result);
                    },
                    error (result) {
                        logResult(result);
                    }
                })).subscribe(observer);
            });
        };
    };
}

export { loggerLink };
